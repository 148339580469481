@import "themes";
@import "~@nebular/theme/styles/globals";
@include nb-install() {
  @include nb-theme-global();
}

@import "~bootstrap/scss/bootstrap";
@import "~highlight.js/styles/monokai.css";

#tsparticles {
  height: 100%;
  width: 100%;
}

pre {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

nb-layout-column {
  max-height: calc(100vh - 36px - 36px - 76px);
}
